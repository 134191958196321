import {Box, Button, useTheme} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../../theme";
import Header from "../../Header";
import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";

const DashTable = (
    {
        data,
        label,
        subtitle,
        columns,
        link,
        answer,
        compact,
    }
) => {

    // console.log('\n data', data);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [height, setHeight] = useState(window.innerHeight);
    const topbarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const topbarHeight = topbarRef.current ? topbarRef.current.offsetHeight : 0;
            setHeight(window.innerHeight - topbarHeight - 50);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial call to set the height

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            m="20px"
            display="flex"
            flexDirection="column"
            height={height}
        >
            <Box
                ref={topbarRef}
                flexShrink={0}
            >
                {!compact && <Header
                    title={label}
                    subtitle={subtitle}
                />}
                {link && (
                    <Button
                        variant="contained"
                        startIcon={link.icon}
                        color="secondary"
                        component={Link}
                        to={link.to}
                        sx={{mt: 2}}
                    >
                        {link.text}
                    </Button>
                )}
                {answer && <Box m="20px 0 0 0" color={colors.warning[100]}>{answer}</Box>}
            </Box>
            <Box
                flexGrow={1}
                height={!compact ? height : 600}
                m="40px 0 0 0"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .name-column-warning": {
                        color: colors.warning[100],
                    },
                    "& .name-column--count": {
                        color: colors.blueAccent[300],
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                        display: "flex",
                        justifyContent: "center"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        borderBottom: "none",
                        backgroundColor: colors.primary[800],
                        color: colors.primary[100],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.primary[800],
                        color: colors.primary[100],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& .group-row": {
                        backgroundColor: `${colors.primary[600]} !important`,
                        color: `${colors.warning[100]} !important`,
                    },
                }}
            >
                {columns?.length > 0 && (
                    <DataGrid
                        rows={data}
                        columns={columns}
                        getRowClassName={(params) => (params.row.type === "group" ? "group-row" : "")}
                        density="comfortable"
                        sortingOrder={['desc', 'asc']}
                    />
                )}
            </Box>

        </Box>
    );
};

export default DashTable;