export const contractStatuses = {
    1: "Открыт",
    2: "Приостановлен",
    3: "Завершён",
    4: "Расторгнут",
}

export const contractPostStatuses = {
    1: "resume_contract",
    2: "pause_contract",
    3: "end_contract",
    4: "terminate_contract",
}

export const ordersStatuses =  {
    "1": "Создано",
    "2": "Отправлено",
    "3": "Подписано",
    "5": "Вызов на объект",
    "4": "Отказ",
    "6": "Завершено"
}