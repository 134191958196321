import React, {useEffect, useState} from "react";
import DashTable from "../../UI/Tables/DashTable";
import {tariffsScheme} from "../../UI/Tables/dash_scheme";
import {Button} from "@mui/material";
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import {Link} from "react-router-dom";
import {currentPath} from "../../methods/Settings";
import {getTariffsTemplate} from "../../methods/TariffsData";

export default function Tariffs ({token}) {

    const [tariffs, setTariffs] = useState(null);

    useEffect(() => {

        const getData = async () => {

            const updateTariffs = await getTariffsTemplate(token);
            setTariffs(updateTariffs);
        }

        getData();
    },[token]);

    if (tariffs) console.log('\n tariffs', tariffs);

    const columns = [
        ...tariffsScheme,
        {
            "field": "cool",
            "headerName": "fdgsh",
            "headerAlign": "center",
            "align": "center",
            "flex": 1,
            "renderCell": (params) => {
                return (
                    <Button
                        variant="contained"
                        startIcon={<SellRoundedIcon />}
                        color="warning"
                        component={Link}
                        to={`${currentPath}/tariffs/edit/?tarrif_id=${params.row.id}`}
                        target="_blank"
                    >
                        Редактировать
                    </Button>
                );
            },
        },

    ];

    return (tariffs && <DashTable
        data={tariffs}
        label={"Список тарифов"}
        columns={columns}
    />);
}