import { useEffect, useState } from "react";
import {Routes, Route, useNavigate} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useCookies } from "react-cookie";
import { UserCheck } from "./components/methods/getUser";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./components/UI/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Authorization from "./components/Public/Authorization";
import Calendar from "./scenes/calendar/calendar";
import Companies from "./components/Work/Company/Companies";
import AllUsers from "./components/Work/Company/AllUsers";
import AllProjects from "./components/Work/Company/AllProjects";
import FinancePlan from "./components/Work/Company/FinancePlan";
import Lots from "./components/Work/Subcompany/Lots";
import SubcompaniesDocuments from "./components/Work/Subcompany/SubcompaniesDocuments";
import SubcompaniesEditDocuments from "./components/Work/Subcompany/SubcompaniesEditDocuments";
import Tariffs from "./components/Work/Company/Tariffs";
import EditTariffs from "./components/Work/Tariffs/EditTariffs";
import EditDiscount from "./components/Work/Tariffs/EditDiscount";
import PaymentList from "./components/Work/Payment/PaymentList";
import EditCompany from "./components/Work/Company/EditCompany";
import OrdersByTender from "./components/Work/Subcompany/OredersByTender";

function App() {
    const [cookie] = useCookies(['access']);
    const [token, setToken] = useState(Object.keys(cookie).length > 0 && cookie?.access);
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [isUserAllowed, setIsUserAllowed] = useState(null);
    const currentPath = process.env.PUBLIC_URL;
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            const getUser = await UserCheck(token);
            if (getUser && Object.keys(getUser).length > 0 && getUser?.is_superuser) {
                setIsUserAllowed(getUser);
            } else navigate('/');
        };

        if (token) getData();
        else navigate('/');
    }, [token, navigate]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    {isUserAllowed && <Sidebar isSidebar={isSidebar} user={isUserAllowed} />}
                    <main className="content">
                        {isUserAllowed && <Topbar setIsSidebar={setIsSidebar} />}
                        <Routes>
                            <Route path={currentPath} element={<Authorization setTokenAuth={setToken} />} />

                            {/* Company */}
                            <Route path={`${currentPath}/welcome`} element={<Companies token={token} />} />
                            <Route path={`${currentPath}/all-users`} element={<AllUsers token={token} />} />
                            <Route path={`${currentPath}/all-projects`} element={<AllProjects token={token} />} />
                            <Route path={`${currentPath}/financial-plan/*`} element={<FinancePlan token={token} />} />
                            <Route path={`${currentPath}/tariffs`} element={<Tariffs token={token} />} />
                            <Route path={`${currentPath}/edit-company/*`} element={<EditCompany token={token} />} />

                            {/* subcompany */}
                            <Route path={`${currentPath}/lots`} element={<Lots token={token} />} />
                            <Route path={`${currentPath}/orders-by-tender/*`} element={<OrdersByTender token={token} />} />
                            <Route path={`${currentPath}/subcompanies-documents`} element={<SubcompaniesDocuments token={token} />} />
                            <Route path={`${currentPath}/subcompanies-documents/edit-documents/`} element={<SubcompaniesEditDocuments token={token} />} />

                            {/* payment */}
                            <Route path={`${currentPath}/payment/list`} element={<PaymentList token={token} />} />

                            {/* tariffs */}
                            <Route path={`${currentPath}/tariffs/edit-tariffs`} element={<EditTariffs token={token} />} />
                            <Route path={`${currentPath}/tariffs/edit-discount`} element={<EditDiscount token={token} />} />

                            {/* tests */}
                            <Route path={`${currentPath}/dashboard`} element={<Dashboard />} />
                            <Route path={`${currentPath}/team`} element={<Team />} />
                            <Route path={`${currentPath}/contacts`} element={<Contacts />} />
                            <Route path={`${currentPath}/invoices`} element={<Invoices />} />
                            <Route path={`${currentPath}/form`} element={<Form />} />
                            <Route path={`${currentPath}/bar`} element={<Bar />} />
                            <Route path={`${currentPath}/pie`} element={<Pie />} />
                            <Route path={`${currentPath}/line`} element={<Line />} />
                            <Route path={`${currentPath}/faq`} element={<FAQ />} />
                            <Route path={`${currentPath}/calendar`} element={<Calendar />} />
                            <Route path={`${currentPath}/geography`} element={<Geography />} />
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;