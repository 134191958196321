export const api = process.env.REACT_APP_ERP_API_URL;

export const fileServer = process.env.REACT_APP_ERP_FILE_URL;
export const currentPath = process.env.PUBLIC_URL;

// Define the headers configuration based on request type and token
export const headersRequests = (type, token) => {
    const types = {
        get: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Accept-Language': 'ru',
            }
        },
        post: {
            headers: {
                'accept': '*/*',
                'Authorization': `Bearer ${token}`,
                'Accept-Language': 'ru',
            }
        },
        post_form: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
                'Accept-Language': 'ru',
            }
        },
        post_json: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Accept-Language': 'ru',
            }
        },
        post_auth: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': 'ru',
            }
        }
    };

    return !token ? types["post_auth"] : types[type];
};

export const dadataOptions = {
    "options": {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Token 0081d13554403402faae0bd889c3275bf36e1dd1',
            'Accept-Language': 'ru',
        }
    },
    "company": "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
    "bank": "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank"
}