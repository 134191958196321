import React, {useEffect, useState} from "react";
import {acceptPayments, declinePayments, getPayments} from "../../methods/payments";
import DashTable from "../../UI/Tables/DashTable";
import {payScheme} from "../../UI/Tables/dash_scheme";
import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import Modal from "@mui/material/Modal";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

export default function PaymentList({token}) {

    const [payList, setPayList] = useState(null);
    const [approvePayDetails, setApprovePayDetails] = useState(null);
    const [answer, setAnswer] = useState(null);

    useEffect(() => {

        const getData = async () => {

            const getPaymentsData = await getPayments(token);
            // console.log('\n getPaymentsData', getPaymentsData);

            setPayList(getPaymentsData);
        }

        getData();
    }, [token]);

    // if (payList) console.log('\n payList', payList);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const acceptPayHandler = (id) => {

        setAnswer(null);

        const payment = payList.find(p => p.id === id);

        const acceptPayData = async (e) => {
            
            e.preventDefault();
            setApprovePayDetails(null);
            
            const formData = new FormData(e.target);
            // console.log('\n formData', formData);
            const response = await acceptPayments(token, payment.id, formData);
            // console.log('\n ', response);

            if (response.success) {
                const getPaymentsData = await getPayments(token);

                setPayList(getPaymentsData);
            }
            else setAnswer(response);
        }

        const details = (<Box>
                <Typography variant="h4">Подтвердить поступление денежных средств</Typography>
                <Typography>Для компании {payment.company_name} на сумму {payment.amount}р.</Typography>
                <form onSubmit={acceptPayData}>
                    <Box className={`flex flex-row gap-2 my-3`}>
                        <TextField
                            name="fact_amount"
                            label="Сумма поступления"
                            variant="outlined"
                            type="number"
                            className={`w-full`}
                        />
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                            color="primary"
                            sx={{width: "150px"}}
                        >
                            Платежный документ
                            <VisuallyHiddenInput
                                type="file"
                                name="document"
                                onChange={(event) => console.log(event.target.files)}
                                multiple
                            />
                        </Button>
                    </Box>
                    <Button
                        variant="contained"
                        startIcon={<DoneRoundedIcon />}
                        color="success"
                        type="submit"
                        sx={{width: "100%"}}
                    >
                        Принять
                    </Button>
                </form>
            </Box>);
        setApprovePayDetails(details);
    };
    const declinePayHandler = async (id) => {

        setAnswer(null);

        const payment = payList.find(p => p.id === id);

        const declinePayData = async () => {

            const response = await declinePayments(token, payment.id);
            // console.log('\n ', response);

            if (response.success) {
                const getPaymentsData = await getPayments(token);
                setApprovePayDetails(null);

                setPayList(getPaymentsData);
            }
            else setAnswer(response);
        }

        const details = (<Box>
            <Typography variant="h4">Отклонить поступление денежных средств?</Typography>
            <Typography>Для компании {payment.company_name} на сумму {payment.amount}р.</Typography>
            <Box className={`flex flex-row gap-2`}>
                <Button
                    variant="contained"
                    startIcon={<DoneRoundedIcon />}
                    color="success"
                    sx={{width: "100%"}}
                    onClick={declinePayData}
                >
                    Да
                </Button>
                <Button
                    variant="contained"
                    startIcon={<ClearRoundedIcon />}
                    color="warning"
                    sx={{width: "100%"}}
                    onClick={() => setApprovePayDetails(null)}
                >
                    Нет
                </Button>
            </Box>
        </Box>);
        setApprovePayDetails(details);
    };
    const columns = [
        ...payScheme,
        {
            "field": "accept",
            "headerName": "Принять",
            "align": "center",
            "headerAlign": "center",
            "flex": 1,
            "renderCell": (params) => {
                if (params.row.status === "Новая") return (
                    <Button
                        variant="contained"
                        startIcon={<DoneRoundedIcon />}
                        color="success"
                        onClick={() => acceptPayHandler(params.row.id)}
                    >
                        Принять
                    </Button>
                );
            },
        },
        {
            "field": "decline",
            "headerName": "Отклонить",
            "align": "center",
            "headerAlign": "center",
            "flex": 1,
            "renderCell": (params) => {
                if (params.row.status === "Новая") return (
                    <Button
                        variant="contained"
                        startIcon={<ClearRoundedIcon />}
                        color="warning"
                        onClick={() => declinePayHandler(params.row.id)}
                    >
                        Отклонить
                    </Button>
                );
            },
        },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            {answer && <Alert sx={{mx: 2, width: "max-content"}} severity={answer.success ? "success" : "error"}>
                {answer.success ? "Данные успешно обновлены" : answer.message}
            </Alert>}
            <Modal
                open={!!approvePayDetails}
                onClose={() => setApprovePayDetails(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {approvePayDetails}
                </Box>
            </Modal>
            {payList && <DashTable
                data={payList}
                label={"Список запросов на пополнение"}
                columns={columns}
            />}
        </Box>
    );
}