import React, {useEffect, useState} from "react";
import Page from "./Page";
import {Link, useLocation} from "react-router-dom";
import {getFinancialPlan, putFinplanUpdateByProject} from "../../methods/financialPlan";
import {Alert, Box, Button} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import DashTable from "../../UI/Tables/DashTable";
import {finScheme} from "../../UI/Tables/dash_scheme";
import EventBusy from "@mui/icons-material/EventBusy";

export default function FinancePlan({token}) {

    const params = new URLSearchParams(useLocation().search);
    const company_id = +params.get('company');
    const project_id = +params.get('project');

    const [financialPlan, setFinancialPlan] = useState(null);
    const [answer, setAnswer] = useState(null);

    // console.log({
    //     financialPlan,
    //     answer,
    // });

    useEffect(() => {

        const fetchData = async () => {
            const response = await getFinancialPlan(token, company_id, project_id);
            if (response?.success) {
                setFinancialPlan(response.data);
            }
            else setAnswer(response);
        };
        fetchData();

    },[token, company_id, project_id]);

    const resetFPHandler = async () => {

        const updateFp = financialPlan.filter(w => w.fact_sum > 0);
        // console.log('\n updateFp', updateFp);

        const fpUpdateData = updateFp.reduce((acc, current, index) => {
            acc[index] = current;
            return acc;
        }, {});

        console.log(fpUpdateData);
        const put = await putFinplanUpdateByProject(token, company_id, project_id, fpUpdateData);
        // console.log('\n put', put);
        if (put?.success) {
            const response = await getFinancialPlan(token, company_id, project_id);
            if (response?.success) {
                setFinancialPlan(response.data);
            }
            else setAnswer(response);
        } else {
            setAnswer(put);
        }
    }

    return (
        <Page
            label="Редактирование финансового плана"
        >
            {answer && <Alert sx={{mx: 2, width: "max-content"}} severity={answer.success ? "success" : "error"}>
                {answer.success ? "Данные успешно обновлены" : answer.message}
            </Alert>}
            <Box className={`flex flex-row gap-2`}>
                <Button
                    color="info"
                    startIcon={<ArrowBackRoundedIcon />}
                    variant="contained"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/all-projects/`}
                    sx={{ width: "max-content" }}
                >
                    Назад
                </Button>
                <Button
                    color="warning"
                    startIcon={<EventBusy />}
                    variant="contained"
                    sx={{ width: "max-content" }}
                    onClick={resetFPHandler}
                >
                    Сбросить финансовый план
                </Button>
            </Box>
            {financialPlan && <DashTable
                data={financialPlan}
                columns={finScheme}
                compact
            />}
        </Page>
    );
}
