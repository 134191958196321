import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {getCompanyData, markCompanyAsPaid, refreshDadata, resetCompanyDetails} from "../../methods/getCompanies";
import Page from "./Page";
import { Alert, Box, Button, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { companyTypes, editCompanyFields, fieldsTypes } from "../../UI/Tables/dash_scheme";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

export default function EditCompany({ token }) {
    const params = new URLSearchParams(useLocation().search);
    const company_id = +params.get('company_id');

    const [company, setCompany] = useState(null);
    const [blocked, setBlocked] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [answer, setAnswer] = useState(null);
    const companyFields = company ? editCompanyFields[company.type] : null;

    useEffect(() => {
        const fetchData = async () => {
            const response = await getCompanyData(token, company_id);
            if (response.success) {
                setCompany(response.data);
            }
            else setAnswer(response);
        };
        fetchData();
    }, [token, company_id]);

    const fieldsChangeHandler = (e) => {
        const { name, value } = e.target;
        setIsChanged(true);
        setCompany(prev => ({ ...prev, [name]: value }));
    };
    const refreshDataHandler = async (e) => {
        const type = e.target.name;
        const query = type === 'company' ? company.inn : company.bik;
        const response = await refreshDadata(type, query);

        if (response.success) {
            const data = response?.data?.suggestions[0];
            const refreshData = type === 'company' ? data.data : data;
            const fields = companyFields[type].filter(f => f.update);

            const updateCompany = {...company};

            fields.forEach(field => {

                // console.log('\n ', getFieldValue(type, field.name, refreshData, updateCompany));
                updateCompany[field.name] = getFieldValue(type, field.name, refreshData, updateCompany);
            });

            setCompany(updateCompany);
            setIsChanged(true);
        }
    };
    const getFieldValue = (type, fieldName, refreshData, prevCompany) => {
        let value;
        if (type === 'company') {
            switch (fieldName) {
                case 'name':
                    value = refreshData.name?.short_with_opf || prevCompany.name;
                    break;
                case 'inn':
                    value = refreshData.inn || prevCompany.inn;
                    break;
                case 'kpp':
                    value = refreshData.kpp || prevCompany.kpp;
                    break;
                case 'ogrn':
                    value = refreshData.ogrn || prevCompany.ogrn;
                    break;
                case 'okpo':
                    value = refreshData.okpo || prevCompany.okpo;
                    break;
                case 'legal_address':
                case 'postal_address':
                    value = refreshData.address?.value || prevCompany.legal_address;
                    break;
                case 'director':
                    value = refreshData.management?.name || prevCompany.director;
                    break;
                default:
                    value = prevCompany[fieldName];
            }
        } else if (type === 'bank') {
            switch (fieldName) {
                case 'bank':
                    value = refreshData.data.name?.short || prevCompany.bank;
                    break;
                case 'correspondent_account':
                    value = refreshData.data.correspondent_account || prevCompany.correspondent_account;
                    break;
                case 'bik':
                    value = refreshData.data.bic || prevCompany.bik;
                    break;
                default:
                    value = prevCompany[fieldName];
            }
        }
        return value;
    };
    const saveCompanyDataHandler = async (e) => {
        
        e.preventDefault();


        const response = await resetCompanyDetails(token, company_id, company);

        if (response.success) {
            setAnswer({
                ...response,
                message: "Данные обновлены"
            });
        }
        else setAnswer(response);
    }

    const changePayCompanyHandler = async () => {

        setAnswer(null);

        const response = await markCompanyAsPaid(token, company_id, !company.is_paid);

        if (response.success) {
            setAnswer({
                ...response,
                message: "Данные обновлены"
            });

            setCompany(prev => ({ ...prev, "is_paid": response.data.is_paid }));
        }
        else setAnswer(response);
    }

    const alertStyle = {
        width: { xs: "100%", sm: "auto" },
        whiteSpace: { xs: "normal", sm: "break-spaces" },
        wordBreak: "break-word",
    };

    return (
        <Page
            label="Редактирование компании"
            subtitle={`${companyTypes[company?.type]} ${company?.name}`}
        >
            {answer && <Alert sx={{mx: 2, width: "max-content"}} severity={answer.success ? "success" : "error"}>
                {answer.success ? "Данные успешно обновлены" : answer.message}
            </Alert>}
            <Button
                color="info"
                startIcon={<ArrowBackRoundedIcon />}
                variant="contained"
                component={Link}
                to={`${process.env.PUBLIC_URL}/welcome/`}
                sx={{ width: "max-content" }}
            >
                Назад
            </Button>

            <Box className={`flex flex-col md:flex-row gap-2`}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={blocked}
                            onChange={() => setBlocked(!blocked)}
                            color={"warning"}
                        />
                    }
                    label={`${!blocked ? "Заблокированный" : "Разблокированный"} ввод`}
                />
                {blocked && (
                    <Alert severity="warning" sx={alertStyle}>Разрешен ввод данных в поля, которые должны проходить проверку севисом Dadata</Alert>
                )}
            </Box>

            {isChanged && (
                <Alert severity="warning" sx={alertStyle}>Изменены данные компании, сохраните результат</Alert>
            )}

            {company && <FormControlLabel
                control={
                    <Switch
                        checked={company.is_paid}
                        onChange={changePayCompanyHandler}
                        color={"success"}
                    />
                }
                label={`Компания ${!company.is_paid ? "не" : ""} оплачена`}
            />}

            {(companyFields && company) && (
                <form onSubmit={saveCompanyDataHandler}>
                    <Box className={`flex flex-col md:flex-row gap-2 my-2`}>
                        {Object.keys(companyFields).map(type => {
                        const fields = companyFields[type];

                        return (
                            <Box key={type}>
                                <Typography variant={"h4"}>{fieldsTypes[type]}</Typography>
                                <Box className={`grid md:grid-cols-2 gap-2 my-2`}>
                                    {fields.map(field => (
                                        <TextField
                                            key={field.name}
                                            name={field.name}
                                            label={field.label}
                                            variant="outlined"
                                            type="text"
                                            value={company[field.name] || ''}
                                            disabled={!blocked && field.blocked}
                                            onChange={fieldsChangeHandler}
                                        />
                                    ))}
                                </Box>
                                <Button
                                    name={type}
                                    startIcon={<RefreshRoundedIcon />}
                                    className={`w-full`}
                                    variant="contained"
                                    color="secondary"
                                    onClick={refreshDataHandler}
                                >
                                    Обновить данные
                                </Button>
                            </Box>
                        );
                    })}
                    </Box>
                     {isChanged && (<Button
                        startIcon={<SaveOutlinedIcon />}
                        type="submit"
                        color="success"
                        variant="contained"
                    >Сохранить</Button>)}
                </form>
            )}
        </Page>
    );
}