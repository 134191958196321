import {ErrorOccurred} from "./ErrorOccurred";
import axios from "axios";
import {api, headersRequests} from "./Settings";
import {payment_status} from "../UI/Tables/dash_scheme";

export const getPayments = async (token) => {

    try {
        const response = await axios.get(
            `${api}/payments/requests/`,
            headersRequests('get', token)
        );
        // console.log('\n response', response.data);
        const list = response.data.map(p => {

            const company_name = p.company.name;
            const company_id = p.company.id;
            const status = payment_status[p.status];

            return ({
                ...p,
                company_name,
                company_id,
                status,
            })
        });
        return (list);
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'getPayments');
    }
}

export const acceptPayments = async (token, id, data) => {


    try {

        const response = await axios.post(
            `${api}/payments/requests/${id}/accept/`,
            data,
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        if (response.data) {
            return ({
                success: true,
                data: response.data
            })
        }
    }
    catch (error) {
        console.log('\n getEstimateData', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `id: ${id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}

export const declinePayments = async (token, id) => {


    try {

        const response = await axios.post(
            `${api}/payments/requests/${id}/decline/`,
            '',
            headersRequests('post_form', token)
        );

        // console.log('\n patchTariffTemplate', response.data);

        if (response.data) {
            return ({
                success: true,
                data: response.data
            })
        }
    }
    catch (error) {
        console.log('\n declinePayments', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `id: ${id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}