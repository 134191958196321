import {useState} from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../../theme";
import Business from "@mui/icons-material/Business";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MapsHomeWork from "@mui/icons-material/MapsHomeWork";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import EditLocation from "@mui/icons-material/EditLocation";
import FilePresent from "@mui/icons-material/FilePresent";
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import WorkIcon from '@mui/icons-material/Work';

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
            title={title}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = ({user}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem('collapsed') === "1");
    const [selected, setSelected] = useState("Dashboard");

    const collapsedHandler = (state) => {

        setIsCollapsed(state);

        state ? localStorage.setItem('collapsed', '1') : localStorage.setItem('collapsed', '');
    }

    const items = [
        {
            "section": "Управление компаниями",
            "items": [
                {
                    "title": "Компании",
                    "icon": <Business />,
                    "to": "/welcome"
                },
                {
                    "title": "Запросы на пополнение",
                    "icon": <AddCardRoundedIcon />,
                    "to": "/payment/list"
                },
                {
                    "title": "Пользователи",
                    "icon": <PeopleOutlinedIcon />,
                    "to": "/all-users"
                },
                {
                    "title": "Проекты",
                    "icon": <MapsHomeWork />,
                    "to": "/all-projects"
                },
            ],
        },
        {
            "section": "Управление подрядчиками",
            "items": [
                {
                    "title": "Список лотов",
                    "icon": <WorkIcon />,
                    "to": "/lots"
                },
                // {
                //     "title": "Управление предписаниями",
                //     "icon": <EditLocation />,
                //     "to": "/orders"
                // },
                {
                    "title": "Управление документами",
                    "icon": <FilePresent />,
                    "to": "/subcompanies-documents"
                },
            ],
        },
        {
            "section": "Управление тарифами",
            "items": [
                {
                    "title": "Управление тарифами",
                    "icon": <CandlestickChartOutlinedIcon />,
                    "to": "/tariffs/edit-tariffs"
                },
                {
                    "title": "Управление скидками",
                    "icon": <RequestQuoteOutlinedIcon />,
                    "to": "/tariffs/edit-discount"
                },
            ],
        },
    ];

    // console.log('\n Sidebar user', user);

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: isCollapsed ? "5px 35px 5px 20px !important" : "5px !important",
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important",
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => collapsedHandler(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography noWrap variant="h5" color={colors.grey[100]}>
                                    Система управления
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {user?.short_name}
                                </Typography>
                                <Typography variant="h5" color={colors.greenAccent[500]}>
                                    {user?.email}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>

                        {items?.map((item, index) => {

                            const itemContainer = ([
                                !isCollapsed && <Typography
                                    key={index}
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: "15px 0 5px 20px" }}
                                >
                                    {item.section}
                                </Typography>
                            ]);

                            item.items.forEach((i,id) => {

                                itemContainer.push(
                                    <Item
                                        key={`${id}-${index}`}
                                        title={i.title}
                                        to={`${process.env.PUBLIC_URL}${i.to}`}
                                        icon={i.icon}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                );
                            });

                            return (
                                itemContainer
                            );
                        })}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;