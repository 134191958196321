import React from "react";
import {Link} from "react-router-dom";
import {Box, Button, Tooltip, useTheme} from "@mui/material";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import Typography from "@mui/material/Typography";
import CreditCardOffRoundedIcon from '@mui/icons-material/CreditCardOffRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import CurrencyRubleRoundedIcon from '@mui/icons-material/CurrencyRubleRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import {tokens} from "../../../theme";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {Price} from "../global/Price";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {ordersStatuses} from "../global/stauses";

export const companyTypes = {
    1: "Физ. лицо",
    2: "Юр. лицо",
    3: "ИП",
}

export const companyScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "headerAlign": "center",
        "type": "number",
        "width": 10,
    },
    {
        "field": "name",
        "headerName": "Компания",
        "headerAlign": "center",
        "cellClassName": "name-column--cell",
        "flex": 1,
    },
    {
        "field": "email",
        "headerName": "Почта",
        "headerAlign": "center",
        "flex": 1,
    },
    {
        "field": "inn",
        "headerName": "ИНН",
        "headerAlign": "center",
    },
    {
        "field": "subcompany",
        "headerName": "Тип",
        "type": "boolean",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Typography
                    sx={{
                        color: params.row.subcompany ? "" : "success.main",
                    }}
                >
                    {params.row.subcompany_label}
                </Typography>
            );
        }
    },
    {
        "field": "is_paid",
        "headerName": "Оплата",
        "headerAlign": "center",
        "type": "boolean",
        "flex": 0.5,
        "renderCell": (params) => {

            return (!params.row.subcompany &&
                <Typography
                    color={!params.row.is_paid ? "error" : "secondary"}
                >
                    {!params.row.is_paid ?
                        <CreditCardOffRoundedIcon
                            sx={{height: 13}}
                        /> :
                        <CreditScoreRoundedIcon
                            sx={{height: 13}}
                        />
                    }
                    {params.row.is_paid_status}
                </Typography>
            );
        }
    },
    {
        "field": "state_account",
        "headerName": "Состояние счета",
        "headerAlign": "center",
        "type": "number",
        "flex": 0.5,
        "renderCell": (params) => {

            // if (!params.row.subcompany) console.log('\n params.row.is_paid_status', +params.row.state_account >= 0);

            return (!params.row.subcompany &&
                <Typography
                    sx={{
                        color: params.row.state_account >= 0 ? "success.main" : "error.main",
                    }}
                >
                    {params.row.state_account}
                    <CurrencyRubleRoundedIcon
                        sx={{
                            height: 10,
                        }}
                    />
                </Typography>
            );
        }
    },
    {
        "field": "type",
        "headerName": "Лицо",
        "headerAlign": "center",
        "type": "number",
        "flex": 0.5,
        "align": "center",
        "renderCell": (params) => {
            return (
                <Button
                    variant="contained"
                    startIcon={params.row.type === 1 ?
                        <Person2RoundedIcon/> :
                        params.row.type === 2 ?
                            <ApartmentRoundedIcon/> :
                            <PeopleRoundedIcon/>
                    }
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/edit-company/?company_id=${params.id}`}
                >
                    {params.row.type_label}
                </Button>
            );
        }
    }
];

export const usersScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "flex": 0.1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "company_name",
        "headerName": "Компания",
        "flex": 2,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "email",
        "headerName": "Email",
        "cellClassName": "name-column--cell",
        "flex": 2,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "full_name",
        "headerName": "Полные инициалы",
        "flex": 2,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "short_name",
        "headerName": "Краткие инициалы",
        "flex": 2,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "is_active",
        "headerName": "Доступ",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "is_blocked",
        "headerName": "Заблокирован",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "is_deleted",
        "headerName": "Уволен",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "projects",
        "headerName": "Проекты",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "role",
        "headerName": "Роли/Должности",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "updated_at",
        "headerName": "Дата изменения",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    }
];

export const projectsScheme = [
    {
        "field": "id",
        "headerName": "ID проекта",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "company_id",
        "headerName": "ID Компании",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "company_name",
        "headerName": "Компания",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "name",
        "headerName": "Наименование",
        "cellClassName": "name-column--cell",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "action_label",
        "headerName": "Доступ",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "financial",
        "headerName": "Финансовый план",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            console.log('\n ', params.row);
            return (
                <Button
                    variant="contained"
                    startIcon={<CalendarMonthOutlinedIcon/>}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/financial-plan/?company=${params.row.company_id}&project=${params.row.id}`}
                >
                    Финансовый план
                </Button>
            );
        },
    },
];

export const ordersScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "type": "number",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "contract",
        "headerName": "№ Договора",
        "cellClassName": "name-column--cell",
        headerAlign: 'center',
        align: 'center',
        "flex": 1,
    },
    {
        "field": "status",
        "headerName": "Статус",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return ordersStatuses[params.value];
        },
    },
    {
        "field": "category",
        "headerName": "Категория",
        "type": "number",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "date_completion",
        "headerName": "Дата устранения",
        headerAlign: 'center',
        align: 'center',
        "type": "date",
        "renderCell": (params) => {
            return (new Date(params.value).toLocaleDateString());
        },
    }
];

export const subcompaniesDocumentsScheme = [
    {
        "field": "tender_name",
        "headerName": "Тендер",
        headerAlign: 'center',
        align: 'center',
        "flex": 1
    },
    {
        "field": "tender_company_name",
        "headerName": "Компания",
        headerAlign: 'center',
        align: 'center',
        "flex": 1
    },
    {
        "field": "tender_subcompany_name",
        "headerName": "Подрядчик",
        headerAlign: 'center',
        align: 'center',
        "flex": 1
    },
    {
        "field": "register_documents_length",
        "headerName": "Документы при регистрации",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                params.row.register_documents_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon/>}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=1&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.register_documents_length})
                </Button>
            );
        },
    },
    {
        "field": "register_acts_length",
        "headerName": "Акты передачи строительного объекта",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                params.row.register_acts_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon/>}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=2&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.register_acts_length})
                </Button>
            );
        },
    },
    {
        "field": "quality_documents_length",
        "headerName": "Документы о качестве выполненных работ",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                params.row.quality_documents_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon/>}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=3&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.quality_documents_length})
                </Button>
            );
        },
    },
    {
        "field": "work_payment_certificates_length",
        "headerName": "Акты выполненных работ",
        "flex": 1,
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                params.row.work_payment_certificates_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon/>}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=4&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.work_payment_certificates_length})
                </Button>
            );
        },
    },
];

export const tariffsScheme = [
    {
        "field": "id",
        "headerName": "ID",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "name",
        "headerName": "Наименование",
        "cellClassName": "name-column--cell",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "administrator",
        "headerName": "Администратор",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                <Box>
                    <Typography>
                        {params.row.administrator}
                    </Typography>
                    <Typography>
                        {params.row.administrator_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "coordinator",
        "headerName": "Координатор",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.coordinator}
                    </Typography>
                    <Typography>
                        {params.row.coordinator_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "manager",
        "headerName": "Начальник участка",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.manager}
                    </Typography>
                    <Typography>
                        {params.row.manager_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "build_control",
        "headerName": "Стройконтроль",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.build_control}
                    </Typography>
                    <Typography>
                        {params.row.build_control_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "gip",
        "headerName": "ГИП",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.gip}
                    </Typography>
                    <Typography>
                        {params.row.gip_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "contract",
        "headerName": "Подрядчик (Лот)",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.contract}
                    </Typography>
                    <Typography>
                        {params.row.contract_price}
                    </Typography>
                </Box>
            );
        },
    },
];

export const tariffsEditScheme = [
    {
        "field": "id",
        "headerName": "ID",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "name",
        "headerName": "Наименование",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "payment_per_month",
        "headerName": "Оплата в месяц",
        headerAlign: 'center',
        align: 'center',
    },
];

export function useTableColorsScheme() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return {

        "& .MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-cell": {
            borderBottom: "none",
        },
        "& .name-column--cell": {
            color: colors.greenAccent[300],
        },
        "& .name-column-warning": {
            color: colors.warning[100],
        },
        "& .name-column--count": {
            color: colors.blueAccent[300],
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
            display: "flex",
            justifyContent: "center"
        },
        "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
            backgroundColor: colors.primary[800],
            color: colors.primary[100],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[800],
            color: colors.primary[100],
        },
        "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
        },
        "& .group-row": {
            backgroundColor: `${colors.primary[600]} !important`,
            color: `${colors.warning[100]} !important`,
        },
    }
}

export default useTableColorsScheme;

export const discountsScheme = [
    {
        "field": "id",
        "headerName": "ID",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "amount_from",
        "headerName": "Количество от",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "amount_to",
        "headerName": "Количество до",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "percent",
        "headerName": "% скидки",
        headerAlign: 'center',
        align: 'center',
    },
];

export const payScheme = [
    {
        "field": "id",
        "headerName": "ID",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "company_id",
        "headerName": "Id компании",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "company_name",
        "headerName": "Компания",
        headerAlign: 'center',
        align: 'center',
        "flex": 1,
    },
    {
        "field": "description",
        "headerName": "Описание",
        headerAlign: 'center',
        align: 'center',
        "flex": 1,
    },
    {
        "field": "amount",
        "headerName": "На сумму",
        headerAlign: 'center',
        align: 'center',
        "flex": 1,
    },
    {
        "field": "status",
        "headerName": "СТатус",
        headerAlign: 'center',
        align: 'center',
    },
]

export const payment_status = {
    "new": "Новая",
    "approved": "Согласовано",
    "declined": "Отклонено",
}

export const editCompanyFields = {
    1: {
        "company": [
            {
                "name": "name",
                "blocked": false,
                "label": "Ф.И.О.",
                "update": false,
            },
            {
                "name": "phone",
                "blocked": false,
                "label": "Телефон",
                "update": false,
            },
        ]
    },
    2: {
        "company": [
            {
                "name": "name",
                "blocked": true,
                "label": "Наименование компании",
                "update": true,
            },
            {
                "name": "legal_address",
                "blocked": true,
                "label": "Юр. адрес",
                "update": true,
            },
            {
                "name": "postal_address",
                "blocked": false,
                "label": "Почт. адрес",
                "update": false,
            },
            {
                "name": "kpp",
                "blocked": true,
                "label": "КПП",
                "update": true,
            },
            {
                "name": "inn",
                "blocked": true,
                "label": "ИНН",
                "update": true,
            },
            {
                "name": "ogrn",
                "blocked": true,
                "label": "ОГРН",
                "update": true,
            },
            {
                "name": "okpo",
                "blocked": true,
                "label": "ОКПО",
                "update": true,
            },
            {
                "name": "director",
                "blocked": false,
                "label": "Ген. директор (Ф.И.О.)",
                "update": true,
            },
            {
                "name": "phone",
                "blocked": false,
                "label": "Телефон",
                "update": false,
            },
            {
                "name": "basis",
                "blocked": false,
                "label": "Основание полномочий",
                "update": false,
            },
        ],
        "bank": [
            {
                "name": "bank",
                "blocked": true,
                "label": "Наименование банка",
                "update": true,
            },
            {
                "name": "settlement_account",
                "blocked": false,
                "label": "Р/С",
                "update": false,
            },
            {
                "name": "correspondent_account",
                "blocked": true,
                "label": "К/С",
                "update": true,
            },
            {
                "name": "bik",
                "blocked": true,
                "label": "БИК",
                "update": true,
            },
        ],
    },
    3: {
        "company": [
            {
                "name": "name",
                "blocked": true,
                "label": "Наименование компании",
                "update": true,
            },
            {
                "name": "postal_address",
                "blocked": false,
                "label": "Почт. адрес",
                "update": false,
            },
            {
                "name": "inn",
                "blocked": true,
                "label": "ИНН",
                "update": true,
            },
            {
                "name": "ogrn",
                "blocked": true,
                "label": "ОГРН",
                "update": true,
            },
            {
                "name": "okpo",
                "blocked": true,
                "label": "ОКПО",
                "update": true,
            },
            {
                "name": "director",
                "blocked": false,
                "label": "Ген. директор (Ф.И.О.)",
                "update": false,
            },
            {
                "name": "phone",
                "blocked": false,
                "label": "Телефон",
                "update": false,
            },
            {
                "name": "basis",
                "blocked": false,
                "label": "Основание полномочий",
                "update": false,
            },
        ],
        "bank": [
            {
                "name": "bank",
                "blocked": true,
                "label": "Наименование банка",
                "update": true,
            },
            {
                "name": "settlement_account",
                "blocked": false,
                "label": "Р/С",
                "update": false,
            },
            {
                "name": "correspondent_account",
                "blocked": true,
                "label": "К/С",
                "update": true,
            },
            {
                "name": "bik",
                "blocked": true,
                "label": "БИК",
                "update": true,
            },
        ],
    },
}

export const fieldsTypes = {
    "company": "Редактировать реквизиты компании",
    "bank": "Редактировать реквизиты банка",
}

export const finScheme = [
    {
        "field": "ssr",
        "headerName": "ССР",
        headerAlign: 'center',
        align: 'center',
    },
    {
        "field": "name",
        "headerName": "Наименование работ",
        headerAlign: 'center',
        align: 'center',
        "flex": 1,
    },
    {
        "field": "summary_periods",
        "headerName": "Общая сметная стоимость",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return Price(params.value);
        },
    },
    {
        "field": "fact_sum",
        "headerName": "Сумма выполненных работ",
        headerAlign: 'center',
        align: 'center',
        "renderCell": (params) => {
            return (
                params.value > 0 && (
                    <Tooltip title="Невозможно сбросить работу, есть факт выполненных работ!"
                             componentsProps={{tooltip: {sx: {bgcolor: 'red', fontSize: 14}}}}>
                        <Box>
                            <LockOutlinedIcon/>
                            {Price(params.value)}
                        </Box>
                    </Tooltip>
                )
            );
        },
    }
];