import axios from "axios";
import {api, headersRequests} from "./Settings";
import {ErrorOccurred} from "./ErrorOccurred";
import {sendRequestsSequentially} from "./webWorker";

export const allWorkJournals = async (token, company_id, project_id) => {

    try {

        const response = await axios.get(
            `${api}/work-journal/companies/${company_id}/projects/${project_id}/all-work-journals`,
            headersRequests('get', token)
        );

        return {
            success: true,
            data: response.data,
        };
    }
    catch (error) {

        console.log('\n allWorkJournals', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `company_id: ${company_id} project_id: ${project_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};

export const getFinancialPlan = async (token, company_id, project_id) => {

    try {

        const getJournals = await allWorkJournals(token, company_id, project_id);
        const journalsSSR = {};

        if (getJournals.success) getJournals.data.forEach((j) => {

            j.estimate.forEach(w => {

                const logValuesSum = w?.work_log?.map(l => l.certificates_completed_work && +l.value).reduce((acc, val) => acc + val, 0);
                const price = +w.unit_cost_smr + +w.unit_cost_materials;

                if (!journalsSSR[w.ssr]) journalsSSR[w.ssr] = 0;

                journalsSSR[w.ssr] += logValuesSum * price;
            });
        });
        // console.log('\n journalsSSR', journalsSSR);
        // if (getJournals.success) console.log('\n getJournals', getJournals.data);

        const getFp = await axios.get(
            `${api}/finplan/companies/${company_id}/projects/${project_id}/`,
            headersRequests('get', token)
        );
        const fp1 = Object.values(getFp?.data?.financial_plan?.json)?.map((row, id) => {
            const parent = row?.ssr?.includes('.') ? row?.ssr?.split('.')?.slice(0, -1)?.join('.') : null;
            const summary_periods = Object.values(row.periods).reduce((acc, val) => acc + val, 0);
            const fact_sum = journalsSSR[row?.ssr] || 0; // Инициализируем как 0, если undefined

            return {
                ...row,
                id,
                parent,
                summary_periods,
                fact_sum,
            };
        });
        const fp2 = fp1.map(row => {
            const periods = row.type === "group" && fp1
                .filter(r => r.parent === row.ssr)
                .flatMap(r => r.periods)
                .reduce((acc, obj) => {
                    for (const [date, value] of Object.entries(obj)) {
                        acc[date] = (acc[date] || 0) + value; // Суммируем значения периодов
                    }
                    return acc;
                }, {});

            const summary_periods = Object.values(periods).reduce((acc, val) => acc + val, 0);

            const fact_sum = row.type === "group" && fp1
                .filter(r => r.parent === row.ssr)
                .reduce((acc, r) => acc + (r.fact_sum || 0), 0); // Суммируем fact_sum по группам, учитывая undefined

            return {
                ...row,
                periods: periods ? periods : row.periods,
                summary_periods: summary_periods > 0 ? summary_periods : row.summary_periods,
                fact_sum: fact_sum > 0 ? fact_sum : row.fact_sum, // Используем суммированный fact_sum
            };
        });
        const fp = fp2.map(row => {
            const periods = row.type === "group" && fp2
                .filter(r => r.parent === row.ssr)
                .flatMap(r => r.periods)
                .reduce((acc, obj) => {
                    for (const [date, value] of Object.entries(obj)) {
                        acc[date] = (acc[date] || 0) + value; // Суммируем значения периодов
                    }
                    return acc;
                }, {});

            const summary_periods = Object.values(periods).reduce((acc, val) => acc + val, 0);

            const fact_sum = row.type === "group" && fp2
                .filter(r => r.parent === row.ssr)
                .reduce((acc, r) => acc + (r.fact_sum || 0), 0); // Суммируем fact_sum по группам, учитывая undefined

            return {
                ...row,
                periods: periods ? periods : row.periods,
                summary_periods: summary_periods > 0 ? summary_periods : row.summary_periods,
                fact_sum: fact_sum > 0 ? fact_sum : row.fact_sum, // Используем суммированный fact_sum
            };
        });

        // console.log('\n fp', fp);

        return {
            success: true,
            data: fp,
        };
    }
    catch (error) {

        console.log('\n getFinancialPlan', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `company_id: ${company_id} project_id: ${project_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};

export const putFinplanUpdateByProject = async (token, company_id, project_id, json) => {

    try {

        const response = await axios.put(
            `${api}/finplan/companies/${company_id}/projects/${project_id}/update-by-project/`,
            {json: json},
            headersRequests('post_json', token)
        );
        console.log('\n response', response);
        return {
            success: true,
            data: response.data,
        };
    }
    catch (error) {

        console.log('\n putFinplanUpdateByProject', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `company_id: ${company_id} project_id: ${project_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}

export const resetFinancialPlan = async (
    token,
    subcompaniesByTenders,
    company_id,
    project_id,
    financialPlan
) => {

    try {

        // console.log('\n resetFinancialPlan', subcompaniesByTenders);
        const resetSubcompaniesSsr = subcompaniesByTenders.map(subcompany => {

            const company_id = subcompany.company_id;
            const project_id = subcompany.project_id;
            const tender_id = subcompany.tender;
            const subcompany_id = subcompany.subcompany;

            return ({
                method: 'post',
                url: `${api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/reset-financial-plan-by-super-admin/ssr/`,
                headers: headersRequests('post_form', token).headers,
            });
        });

        sendRequestsSequentially(resetSubcompaniesSsr).then(results => {
            console.log(results);
        });

        const getAllWorkJournals = await axios.get(
            `${api}/work-journal/companies/${company_id}/projects/${project_id}/all-work-journals`,
            headersRequests('get', token)
        );
        const usedSsr = [...new Set(getAllWorkJournals.data.flatMap(j => j.estimate).filter(e => e.ssr).map(e => e.ssr))];
        const usedSrrFinancialPlan = financialPlan.filter(f => usedSsr.includes(f.ssr));
        const json = {};
        usedSrrFinancialPlan.forEach(f => {

            if (!json[f.ssr]) json[f.ssr] = {
                "ssr": f.ssr,
                "name": f.name,
                "type": f.type,
                "periods": f.periods,
            };
        })
        // console.log('\n usedSsr', usedSsr, json);

        const updateByProject = [
            {
                method: 'put',
                url: `${api}/finplan/companies/${company_id}/projects/${project_id}/update-by-project/`,
                data: {json},
                headers: headersRequests('post_json', token).headers,
            }
        ];
        sendRequestsSequentially(updateByProject).then(results => {
            console.log(results);
            return (results);
        });
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных тендеров компаний', 'getCompanyTenders');
    }
    return [];
};
export const getSubcompaniesInCompany = async (token, company_id) => {

    try {

        const response = await axios.get(
            `${api}/company/${company_id}/subcompanies`,
            headersRequests('get', token)
        );

        return {
            success: true,
            data: response.data.data,
        };
    }
    catch (error) {

        console.log('\n getSubcompaniesInCompany', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `company_id: ${company_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};
export const getSubcompanyLots = async (token, subcompany_id) => {

    try {

        const response = await axios.get(
            `${api}/subcompanies/${subcompany_id}/won-tender-lots`,
            headersRequests('get', token)
        );

        return {
            success: true,
            data: response.data,
        };
    }
    catch (error) {

        console.log('\n getSubcompanyLots', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `subcompani_id: ${subcompany_id} attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}