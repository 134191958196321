import SelectCompany from "./SelectSubcompany";
import {useEffect, useState} from "react";
import {changeLotStatusPost, getSubcompaniesTenders, resetPeriodsPost} from "../../methods/subcompaniesMethods";
import React from "react";
import {Box, Typography} from "@mui/material";
import {contractStatuses} from "../../UI/global/stauses";
import AskUser from "../../UI/Modal/AskUser";

export default function Lots({ token }) {

    const [tenders, setTenders] = useState(null);
    const [modal, setModal] = useState(null);
    const [message, setMessage] = useState(null);

    useEffect(() => {

        const getTenders = async () => {

            const updateTenders = await getSubcompaniesTenders(token);

            // console.log('\n updateTenders', updateTenders);
            setTenders(updateTenders);
        };

        getTenders();
    }, [token]);

    const changeLotStatusHandler = (type, company_id, project_id, subcompany_id, tender_id) => {
        // console.log('\n changeLotStatusHandler', {type, company_id, project_id, subcompany_id, tender_id});

        setMessage(null);

        const changeLotStatus = async (token, type, company_id, project_id, subcompany_id, tender_id) => {
            setModal(null);

            const response = await changeLotStatusPost(token, type, company_id, project_id, subcompany_id, tender_id);
            if (response?.success) {
                // console.log('\n ', response);
                setMessage({
                    success: true,
                    message: `Статус договора на ${contractStatuses[type]} изменен успешно!`
                });

                const updateTenders = await getSubcompaniesTenders(token);
                setTenders(updateTenders);
            } else {
                setMessage(response);
            }
        };

        setModal(
            <Box className={`flex flex-col gap-2`}>
                <Typography variant={'h4'}>
                    Вы действительно хотите изменить статус договора на {contractStatuses[type]}?
                </Typography>
                <AskUser
                    success={() => changeLotStatus(token, type, company_id, project_id, subcompany_id, tender_id)}
                    reject={() => setModal(null)}
                />
            </Box>
        );
    };
    const resetPeriodsHandler = (company_id, project_id, subcompany_id, tender_id) => {
        // console.log('\n changeLotStatusHandler', {type, company_id, project_id, subcompany_id, tender_id});

        setMessage(null);

        const resetPeriods = async (token, company_id, project_id, subcompany_id, tender_id) => {

            setModal(null);

            const response = await resetPeriodsPost(token, company_id, project_id, subcompany_id, tender_id);
            if (response?.success) {
                // console.log('\n ', response);
                setMessage({
                    success: true,
                    message: response.data.detail
                });

                const updateTenders = await getSubcompaniesTenders(token);
                setTenders(updateTenders);
            } else {
                setMessage(response);
            }
        };

        setModal(
            <Box className={`flex flex-col gap-2`}>
                <Typography variant={'h4'}>
                    Вы действительно хотите сбросить периоды сметы подрядчика?
                </Typography>
                <AskUser
                    success={() => resetPeriods(token, company_id, project_id, subcompany_id, tender_id)}
                    reject={() => setModal(null)}
                />
            </Box>
        );
    };

    return (tenders && <SelectCompany
        tenders={tenders}
        message={message}
        modal={modal}
        setModal={setModal}
        changeLotStatusHandler={changeLotStatusHandler}
        resetPeriodsHandler={resetPeriodsHandler}
    />);
}
