import {UserCheck} from "./getUser";
import axios from "axios";
import {api, headersRequests} from "./Settings";
import {ErrorOccurred} from "./ErrorOccurred";
import {contractPostStatuses} from "../UI/global/stauses";

export const getSubcompanies = async (token) => {
    try {
        const getUser = await UserCheck(token);
        if (getUser && Object.keys(getUser).length > 0) {
            const response = await axios.get(`${api}/subcompanies/`, headersRequests('get', token));
            const subcompanies = response?.data?.map(company => {

                return ({
                    ...company,
                });
            });
            // console.log('\n subcompanies', subcompanies);
            return subcompanies;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных подрядчиков', 'subcompaniesMethods');
    }
    return [];
};

export const getSubcompaniesTenders = async (token) => {
    try {
        
        const subcompanies = await getSubcompanies(token);
        
        // console.log('\n subcompanies', subcompanies);

        const getLots = subcompanies.map(async (subcompany) => {

            const response = await axios.get(`${api}/subcompanies/${subcompany.id}/won-tender-lots`, headersRequests('get', token));
            const result = response.data.map(lot => ({...lot, subcompany_info: subcompany}));
            
            // console.log('\n result', result);

            return (result);
        });
        const getAllLots = (await Promise.all(getLots)).flat();
        const tenders = getAllLots.map(lot => {

            const name = lot?.tender?.name;
            const subcompany_name = lot.subcompany_info.name;
            const project_name = lot?.tender?.project?.name;
            const project_id = lot?.tender?.project?.id;
            const company_name = lot?.tender?.project?.company?.name;
            const company_id = lot?.tender?.project?.company?.id;
            const tender_id = lot?.tender?.id;

            // console.log('\n lot', lot);

            return ({
                ...lot,
                name,
                subcompany_name,
                project_name,
                project_id,
                company_name,
                company_id,
                tender_id,
            })
        });
        // console.log('\n tenders', tenders);

        return tenders;
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных подрядчиков', 'getSubcompaniesTenders');
    }
    return [];
};

export const getAllDocuments = async (token) => {
    try {

        const tenders = await getSubcompaniesTenders(token);

        if (tenders?.length > 0) {

            // console.log('\n tenders', tenders);

            const documents = tenders.map(async (tender) => {

                const { company_id, project_id } = tender;

                const subcompany_id = tender.subcompany;
                const tender_id = tender.tender.id;

                const response = await axios.get(
                    `${api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/all-documents`,
                    headersRequests('get', token)
                );
                const result = response.data.data;
                // console.log('\n result', result);

                return ({
                    ...result,
                    tender,
                    tender_name: tender.tender.name,
                    tender_company_name: tender.company_name,
                    tender_subcompany_name: tender.subcompany_info.name,
                    id: tender.tender.id,
                    quality_documents_length: result.quality_documents?.length,
                    register_acts_length: result.register_acts?.length,
                    register_documents_length: result.register_documents?.length,
                    work_payment_certificates_length: result.work_payment_certificates?.length,
                });
            });
            const allDocuments = (await Promise.all(documents)).flat();
            // console.log('\n allDocuments', allDocuments);
            return allDocuments;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения документов', 'getAllDocuments');
    }
    return null;
};

export const changeLotStatusPost = async (token, type, company_id, project_id, subcompany_id, tender_id) => {

    try {
        // console.log('\n changeLotStatusPost', token, type, company_id, project_id, subcompany_id, tender_id);
        // console.log('\n metgod', `${api}/company/${company_id}/subcompanies/${subcompany_id}/projects/${project_id}/tenders/${tender_id}/${contractPostStatuses[type]}/`);

        const response = await axios.post(
            `${api}/company/${company_id}/subcompanies/${subcompany_id}/projects/${project_id}/tenders/${tender_id}/${contractPostStatuses[type]}/`,
            '',
            headersRequests('post', token)
        );
        return {
            success: true,
            data: response.data
        };

    } catch (error) {
        console.error('changeLotStatusPost:', error.response ? error.response.data : error.message);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `changeLotStatusPost - attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}

export const resetPeriodsPost = async (token, company_id, project_id, subcompany_id, tender_id) => {
    try {

        const response = await axios.post(
            `${api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/reset-financial-plan-by-super-admin/periods/`,
            '',
            headersRequests('post', token)
        );
        return {
            success: true,
            data: response.data
        };

    } catch (error) {
    console.error('resetPeriodsPost:', error.response ? error.response.data : error.message);

    return {
        success: false,
        message: error?.response?.data?.errors?.map((e, i) => `resetPeriodsPost - attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
    };
}
}

export const getOrdersByTender = async (token, company_id, project_id, subcompany_id, tender_id) => {
    
    try {
        
        // console.log('\n getOrdersByTender', {token, company_id, project_id, subcompany_id, tender_id});

        const buildings = await axios.get(
            `${api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
            headersRequests('get', token)
        );
        const getSections = buildings?.data?.data?.length > 0 && buildings?.data?.data?.flatMap(async (b) => {

            const response = await axios.get(
                `${api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${b.id}/sections/`,
                headersRequests('get', token)
            );

            // console.log('\n response', response.data);
            return (response.data);
        });
        const allSections = await Promise.allSettled(getSections);
        const sections = allSections.filter(b => b.status === 'fulfilled').flatMap(b => b.value);
        const getOrders = sections?.length > 0 && sections?.map(async (s) => {

            const building_id = s.building;
            const section_id = s.id;

            const response = await axios.get(
                `${api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/`,
                headersRequests('get', token)
            );

            const orders = response?.data?.data?.map(o => ({...o, building_id, section_id}))

            return (orders);
        })
        const allOrders = await Promise.allSettled(getOrders);
        const orders = allOrders.filter(b => b.status === 'fulfilled').flatMap(b => b.value).flat().map(o => ({...o, status: o.history[o.history.length - 1]?.status}));

        return {
            success: true,
            data: orders,
        };
    }
    catch (error) {

        console.log('\n getOrdersByTender', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
}

export const deleteOrder = async (token, order, company_id, project_id) => {
    try {
        const { tender_id, building_id, section_id, id } = order;

        // console.log('\n deleteOrder', { order, company_id, project_id, tender_id, building_id, section_id, id });

        const response = await axios.delete(
            `${api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${id}/delete-by-super-admin/`,
            headersRequests('post', token)
        );

        // console.log('\n response', response);

        if (response.status === 204) {
            return {
                success: true,
                data: 'Предписание успешно удалено'
            };
        }
    } catch (error) {

        console.log('\n deleteOrder', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};

export const changeOrderDateCompletion = async (token, order, company_id, project_id, data) => {
    try {
        const { tender_id, building_id, section_id, id } = order;

        const response = await axios.put(
            `${api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${id}/update-date-completion/`,
            data,
            headersRequests('post_form', token)
        );

        console.log('\n response', response);

        if (response.status >= 200) {
            return {
                success: true,
                data: 'Дата предписния успешно изменена'
            };
        }
    } catch (error) {

        console.log('\n deleteOrder', error.response);

        return {
            success: false,
            message: error?.response?.data?.errors?.map((e, i) => `attr: ${e.attr} detail: ${e.detail} code: ${e.code}`)
        };
    }
};