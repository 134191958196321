import {Alert, Box, Button, TextField} from "@mui/material";
import {useState} from "react";
import {tariffsProps} from "../../methods/TariffsData";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

export default function AddTariffTemplate({message, onSubmit}) {

    const [isChanged, setIsChanged] = useState(false);
    const keys = tariffsProps;

    if (isChanged) console.log('\n isChanged', isChanged);

    return (
        <form className="flex gap-3 flex-col" onSubmit={onSubmit}>
            {message && <Alert severity={message.severity} sx={{ fontSize: 17 }}>{message.message}</Alert>}
            <TextField
                variant="outlined"
                label={keys.name}
                onChange={() => setIsChanged(true)}
                fullWidth
                name="name"
            />

            <table className="w-full md:text-xl max-h-[20vh] overflow-y-auto">
                <thead>
                <tr>
                    <th>Роль</th>
                    <th>Количество</th>
                    <th>Цена</th>
                </tr>
                </thead>
                <tbody>
                {keys.rolesList.map(({ name, label }, index) => (
                    <tr key={index}>
                        <td className="p-2">{label}</td>
                        <td className="text-center p-2">
                            <TextField
                                variant="outlined"
                                type="number"
                                defaultValue={1}
                                onChange={() => setIsChanged(true)}
                                name={name}
                                fullWidth
                            />
                        </td>
                        <td className="text-center">
                            <TextField
                                variant="outlined"
                                type="number"
                                defaultValue={1}
                                onChange={() => setIsChanged(true)}
                                inputProps={{ step: 0.01, min: 0.01 }}
                                name={`${name}_price`}
                                fullWidth
                            />
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={3} className={`py-2`}>
                        <TextField
                            variant="outlined"
                            label="Описание"
                            type="text"
                            onChange={() => setIsChanged(true)}
                            fullWidth
                            name="description"
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={3} className={`py-2`}>
                        <TextField
                            type="file"
                            onChange={() => setIsChanged(true)}
                            fullWidth
                            name="image"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            {isChanged && (
                <Box className="mx-auto w-max">
                    <Button
                        color="success"
                        variant="contained"
                        type="submit"
                        startIcon={<SaveOutlinedIcon />}
                    >
                        Сохранить
                    </Button>
                </Box>
            )}
        </form>
    );
}